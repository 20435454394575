$mat-card-padding: 16px;
$mat-card-border-radius: 4px;
$dashboard-info-icon-size: 42px;
$dashboard-info-counter-size: 30px;
$dashboard-padding: 20px;
.main-container {
  //padding: $dashboard-padding;
  background: $white;
}

.dashboard-header {
  margin-bottom: 20px;
  &__title {
    @extend .h4;
    color: $primary;
  }

  &__desc {
    margin-bottom: 0;
  }
}

mat-card.dashboard-info-card {
  > :first-child {
    margin-top: (-$mat-card-padding);
  }

  .dashboard-info-card__title {
    font-size: 14px;
    margin: (-$mat-card-padding) (-$mat-card-padding) $mat-card-padding;
    padding: $mat-card-padding;
    border-radius: $mat-card-border-radius $mat-card-border-radius 0 0;

    &--primary {
      background: $primary;
      color: color-yiq($primary);
    }

    &--secondary {
      background: $secondary;
      color: color-yiq($secondary);
    }

    &--accent {
      background: map-get($theme-colors, 'accent');
      color: color-yiq(map-get($theme-colors, 'accent'));
    }

    &--warn {
      background: $danger;
      color: color-yiq($danger);
    }
  }

  .dashboard-info-card__info {
    display: flex;
    justify-content: space-between;
    font-size: $dashboard-info-counter-size;
    align-items: center;
    font-weight: bold;

    mat-icon {
      height: $dashboard-info-icon-size;
      width: $dashboard-info-icon-size;
      font-size: $dashboard-info-icon-size;
    }
  }
}
